import * as React from 'react';
import UserContext, { UserContextType } from './UserContext';
import { User, WalletPaymentSource } from './types';
import { getCurrentOrder } from '../OrderContext/actions';
import { getCurrentUser, getUserByOrder } from 'api/userAuthentication';
import { Status } from 'libs/utils/api/types';
import OrderContext from 'contexts/OrderContext/OrderContext';
import { useLocation } from 'react-router-dom';
import { acknowledgeAddressValidation as doAcknowledgeAddressValidation } from 'api/account/settings';
import { Address } from 'bundles/App/pages/Account/Addresses/types';

interface Props {
  orderNumber?: string;
  currentUser?: User | null;
  admin: boolean;
  children: React.ReactNode;
}

const UserContextContainer: React.FunctionComponent<Props> = ({
  orderNumber,
  currentUser,
  admin,
  children,
}) => {
  const { dispatch } = React.useContext(OrderContext);
  const [user, setUser] = React.useState<User>(currentUser);
  const [isInitialMount, setIsInitialMount] = React.useState(true);
  const location = useLocation();

  React.useEffect(() => {
    if (!currentUser) {
      if (admin) {
        getUserByOrder(orderNumber).then(res => {
          if (res.status === Status.Ok) {
            setUser(res.payload.user);
          }
        });
      } else {
        getCurrentUser().then(res => {
          if (res.status === Status.Ok) {
            setUser(res.payload.user);
          }
        });
      }
    }
    setIsInitialMount(false);
  }, []);

  React.useEffect(() => {
    if (!isInitialMount && !admin) {
      getCurrentOrder(dispatch, location.pathname);
    }
  }, [user]);

  const logIn = (u: User) => {
    setUser(u);
  };
  const logOut = () => {
    setUser(null);
  };

  const updateAddresses = React.useCallback(
    (addresses: Address[], defaultShipAddress?: Address, defaultBillAddress?: Address) => {
      setUser({
        ...user,
        addresses,
        defaultBillAddress: defaultBillAddress || user.defaultBillAddress,
        defaultShipAddress: defaultShipAddress || user.defaultShipAddress,
      });
    },
    [user],
  );

  const deleteCreditCard = React.useCallback(
    (id: number) => {
      setUser({
        ...user,
        walletPaymentSources: user.walletPaymentSources.filter(wallet => wallet.id !== id),
      });
    },
    [user],
  );

  const updateCreditCards = React.useCallback(
    (walletPaymentSources: WalletPaymentSource[]) => {
      setUser({
        ...user,
        walletPaymentSources,
      });
    },
    [user],
  );

  const acknowledgeAddressValidation = React.useCallback(() => {
    doAcknowledgeAddressValidation(user).then(res => {
      if (res.status === Status.Ok) {
        setUser({
          ...user,
          addressValidationAcknowledged: true,
        });
      }
    });
  }, [user]);

  const payload: UserContextType = React.useMemo(
    () => ({
      deleteCreditCard,
      updateCreditCards,
      updateAddresses,
      logIn,
      logOut,
      currentUser: user,
      isInitialMount,
      acknowledgeAddressValidation,
    }),
    [user, isInitialMount, deleteCreditCard, updateCreditCards],
  );

  return <UserContext.Provider value={payload}>{children}</UserContext.Provider>;
};

export default UserContextContainer;
